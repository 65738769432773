import store from "@/store.js";

function buildCreateAccount() {
  if (store.getters.userinfo.User.IsSuperAdmin) {
    return {
      _name: "CSidebarNavItem",
      name: "Create Account",
      to: "/createaccount",
      icon: "cil-speedometer",
    };
  } else {
    return {};
  }
}

function buildRemediation() {
  if (store.getters.userinfo.User.IsSuperAdmin) {
    return {
      _name: "CSidebarNavItem",
      name: "Remediation",
      to: "/remediation",
      icon: "cil-speedometer",
    };
  } else {
    return {};
  }
}

function buildAccountNav(activeAccount, componentRef) {
  if (activeAccount == undefined || !componentRef.$isAccountAdmin(activeAccount.accountId)) {
    return {
      _name: "CSidebarNavTitle",
      _children: [""],
    };
  } else {
    // var accountMenuType = "CSidebarNavItem";
    var accountsubItem = [
      {
        name: "Info",
        to: "/accounts/" + activeAccount.accountId + "/info",
      },
    ];

    if (activeAccount.accessStatus === "VALIDATED") {
      // accountMenuType = "CSidebarNavDropdown";
      accountsubItem = [
        {
          name: "Info",
          to: "/accounts/" + activeAccount.accountId + "/info",
        },
        {
          name: "SCPs",
          to: "/accounts/" + activeAccount.accountId + "/scps",
        },
        {
          name: "AWS SSO",
          to: "/accounts/" + activeAccount.accountId + "/awssso",
        },
        // {
        //   name: "Legacy IAM",
        //   to: "/accounts/" + activeAccount.accountId + "/legacyiam",
        // },
      ];
    }

    return {
      _name: "CSidebarNavDropdown",
      name: activeAccount.alias != undefined ? activeAccount.alias : activeAccount.accountId,
      to: "/accounts/" + activeAccount.accountId + "/info",
      icon: "cil-puzzle",
      items: accountsubItem,
      show: true,
      route: "/accounts/" + activeAccount.accountId + "/info",
    };
  }
}

function rendernav(activeAccount, componentRef) {
  if (activeAccount == undefined || componentRef == undefined) {
    return [
      {
        _name: "CSidebarNav",
        _children: [
          {
            _name: "CSidebarNavItem",
            name: "Dashboard",
            to: "/dashboard",
            icon: "cil-speedometer",
            badge: {
              color: "primary",
              text: "NEW",
            },
          },
          {
            _name: "CSidebarNavItem",
            name: "Chargeback",
            to: "/chargeback",
            icon: "cil-dollar",
          },
          {
            _name: "CSidebarNavTitle",
            _children: [""],
          },
          {
            _name: "CSidebarNavItem",
            name: "Help & Documents",
            to: "/help",
            icon: "cil-speedometer",
          },
        ],
      },
    ];
  }
  return [
    {
      _name: "CSidebarNav",
      _children: [
        {
          _name: "CSidebarNavItem",
          name: "Dashboard",
          to: "/dashboard",
          icon: "cil-speedometer",
          badge: {
            color: "primary",
            text: "NEW",
          },
        },
        {
          _name: "CSidebarNavItem",
          name: "Chargeback",
          to: "/chargeback",
          icon: "cil-dollar",
        },
        buildCreateAccount(),
        {
          _name: "CSidebarNavTitle",
          _children: ["Accounts"],
        },
        {
          _name: "CSidebarNavItem",
          name: "Manage Accounts",
          to: "/accounts/dashboard",
          icon: "cil-drop",
        },
        buildAccountNav(activeAccount, componentRef),
        buildRemediation(),
        {
          _name: "CSidebarNavTitle",
          _children: [""],
        },
        {
          _name: "CSidebarNavItem",
          name: "Help & Documents",
          to: "/help",
          icon: "cil-speedometer",
        },
      ],
    },
  ];
}

export default rendernav;
