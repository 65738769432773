<template>
  <CSidebar
    fixed
    :minimize.sync="minimize"
    :show.sync="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/"> EQUANS </CSidebarBrand>

    <!-- <CRenderFunction flat :content-to-render="$options.nav"/> -->
    <CRenderFunction flat :content-to-render="generate" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav_dyn from "./_nav";

export default {
  name: "TheSidebar",
  // nav: nav_dyn(),
  props: {
    currentAccount: Object,
  },

  computed: {
    show() {
      return this.$store.getters.sidebarShow;
    },
    minimize() {
      return this.$store.getters.sidebarMinimize;
    },
    generate() {
      return nav_dyn(this.currentAccount, this);
    },
  },
};
</script>
